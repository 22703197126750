<template lang="pug">
  include ../plugins/pug/btn

  section.section
    div.container
      div.title__panel
        div.title__input
          p Cases
        div.btn.btn__primary.title__btn.disabled
          span Loading....
      div.cases
        div(
          v-for="(casesItem, casesIndex) in cases"
          :key="casesIndex"
        ).cases__item.dialog__window
          div.dialog__window-top
            span
          div.dialog__window-content
            div.cases__body
              div.cases__content
                h2(v-html="casesItem.casesTitle").h2.cases__title
                div.cases__text.text
                  p(
                    v-for="(paragraphItem, paragraphIndex) in casesItem.casesText"
                    :key="paragraphIndex"
                  ) {{ paragraphItem }}
                //div.cases__btn
                //  +btn("About this case")
            div.cases__img
              img(
                :src="casesItem.casesImage"
                alt="cases image"
              )
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
// images
import Cases1 from '@/assets/img/cases-1.png'
import Cases2 from '@/assets/img/cases-2.png'
import Cases3 from '@/assets/img/cases-3.png'
export default {
  name: 'CasesBlock',
  data () {
    return {
      cases: [
        {
          casesTitle: '<strong>Internet</strong> service provider Comprehensive Solution',
          casesText: [
            'We developed a comprehensive, user-friendly solution for an Internet Service Provider that significantly improved the clients experience and streamlined the management of Internet and TV services.',
            'This project resulted in a mobile application and an online personal account that put control of all services directly into the customer\'s hands.'
          ],
          casesImage: Cases1
        },
        {
          casesTitle: 'Online Learning <strong>platform</strong>',
          casesText: [
            'Our team successfully developed an expansive online learning platform, designed to provide a comprehensive solution for remote education and course management.',
            'This project was pivotal in empowering our client to transition into the digital education space, allowing them to reach a global audience and offer a flexible, interactive learning environment.',
            'The platform has received positive feedback for its user-friendly interface and comprehensive feature set, contributing to the growth and success of the client\'s online education.'
          ],
          casesImage: Cases2
        },
        {
          casesTitle: 'Automation Solution for a <strong>Security Company</strong>',
          casesText: [
            'This project represented a significant digital transformation for the security company, bringing them to the forefront of technology in their industry.',
            'Our team delivered an automated solution aimed at optimizing interactions between the security firm and its customers. This project involved the development of a mobile application and a personal dashboard, designed for both company employees and clients.'
          ],
          casesImage: Cases3
        }
      ]
    }
  },
  mounted () {
    this.scrollAnimation()
  },
  methods: {
    scrollAnimation () {
      const elements = document.querySelectorAll(".cases__item")
      let tallestElementHeight = 0
      const cards = gsap.utils.toArray('.cases__item')
      const spacer = 60

      setTimeout(() => {
        elements.forEach(function(element) {
          const elementHeight = element.clientHeight;
          if (elementHeight > tallestElementHeight) {
            tallestElementHeight = elementHeight
          }
        })

        cards.forEach((card, index) => {
          ScrollTrigger.create({
            trigger: card,
            start: `top-=${index * spacer} top+=80px`,
            endTrigger: '.cases',
            end: `bottom top+=${tallestElementHeight + (cards.length * spacer)}`,
            pin: true,
            pinSpacing: false,
            id: 'pin',
            invalidateOnRefresh: true
          })
        })
      }, 300)
    }
  }
}
</script>
